import React from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "30%",
    fixed: true,
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "race",
    width: "15%",
    fixed: true,
  },
  {
    title: "Race",
    dataIndex: "race",
    key: "race",
    width: "25%",
    fixed: true,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: "25%",
    fixed: true,
    responsive: ["md"] as Breakpoint[],
  },
];

type AntRecordsTableProps = {
  tableData: RecordType[];
};

export default function AntRecordsTable(props: AntRecordsTableProps) {
  return (
    <Table dataSource={props.tableData} columns={columns} pagination={false} />
  );
}
