import React from 'react';
import { Row } from 'simple-flexbox';
import { StyleSheet, css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = StyleSheet.create({
    activeBar: {
        height: 56,
        width: 3,
        backgroundColor: '#DDE2FF',
        position: 'absolute',
        left: 0
    },
    activeContainer: {
        backgroundColor: 'rgba(221,226,255, 0.08)'
    },
    activeTitle: {
        color: '#DDE2FF'
    },
    container: {
        height: 56,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(221,226,255, 0.08)'
        },
        paddingLeft: 32,
        paddingRight: 32
    },
    title: {
        fontFamily: 'Muli',
        fontSize: 16,
        lineHeight: '20px',
        letterSpacing: '0.2px',
        color: '#A4A6B3',
        marginLeft: 24
    }
});

function MenuItem(props: any) {
    //const { active, title, onMenuItemClick, faIcon, ...otherProps } = props;
    const opacity = !props.active ? "0.4" : "1";
    return (
        <Row onClick={props.onMenuItemClick} className={css(styles.container, props.active && styles.activeContainer)} vertical="center" >
            {props.active && <div className={css(styles.activeBar)}></div>}
            <FontAwesomeIcon icon={props.faIcon} fill={(props.active && "#DDE2FF").toString()} opacity={opacity} />
            <span className={css(styles.title, props.active && styles.activeTitle)}>{props.title}</span>
        </Row>
    );
}

export default MenuItem;