import React from "react";
import AntRecordsTable from "./AntRecordsTable";

type RecordsTableProps = {
  tableData: RecordType[];
};

export default function RecordsTable(props: RecordsTableProps) {
  return <AntRecordsTable tableData={props.tableData} />;
}
