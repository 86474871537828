import React, { useEffect } from "react";
import useWindowDimensions from "../../Helpers/useWindowDimensions";
import MobileRecords from "./MobileRecords/MobileRecords";
import RecordTabs from "./RecordTabs/RecordTabs";

type RecordsProps = {
  updateSidebar: (e: string) => void;
};

export default function Records(props: RecordsProps) {
  const { updateSidebar } = props;
  const width = useWindowDimensions().width;
  const isMobile = width <= 768;

  useEffect(() => {
    updateSidebar("Club Records");
  }, [updateSidebar]);

  return isMobile ? <MobileRecords /> : <RecordTabs />;
}
