import React, { useEffect } from "react";
import { Row, Col, Card } from "antd";
import TeamPicture from "../../Assets/images/team-picture.jpg";

type HomeProps = {
  updateSidebar: (e: string) => void;
  isMobile: boolean;
};

export default function Home(props: HomeProps) {
  const { updateSidebar, isMobile } = props;
  useEffect(() => {
    updateSidebar("Home");
  }, [updateSidebar]);

  return (
    <div>
      <Row>
        <Col
          span={24}
          xl={16}
          style={{
            padding: 5,
          }}
        >
          <Card>
            <img
              style={{
                borderRadius: 5,
                maxWidth: isMobile ? "100%" : 800,
              }}
              src={TeamPicture}
              alt="athlete"
            />
          </Card>
        </Col>
        <Col span={24} xl={8} style={{ display: "flex", padding: 5 }}>
          <Card title={<h1>Our Mission</h1>} style={{ height: "100%" }}>
            <p style={{ fontSize: 21 }}>
              Runablaze Iowa is based out of Des Moines with club members
              statewide. Our mission is to develop and support post-collegiate,
              Iowa-based distance runners. We aspire to be competitive at
              individual and team events at the local, regional, and national
              level in the sports of road racing, track & field, and cross
              country. Our goal is to provide our athletes as much as they
              require in terms of coaching, training gear, and travel expenses
              to national championship races. Runablaze Iowa was formed in 2006.
            </p>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col span={24} md={12} style={{ padding: 5 }}>
          <Card title="Runablaze on Strava">
            <iframe
              title="strava"
              height="454"
              scrolling="no"
              src="https://www.strava.com/clubs/156533/latest-rides/116789207c186dac4f651a99750dfbd3d9a9bcea?show_rides=true"
              width="100%"
            ></iframe>
          </Card>
        </Col>
      </Row> */}
    </div>
  );
}
