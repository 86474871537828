import React from "react";
import "antd/dist/antd.css";
import { Tabs, Card } from "antd";
import "./record-tabs.scss";
import RecordsTable from "../RecordsTable/RecordsTable";
import recordsData from "../../../Data/records.json";

const { TabPane } = Tabs;

export default function RecordTabs() {
  return (
    <Tabs defaultActiveKey="marathon">
      <TabPane tab="Marathon" key="marathon">
        <Card title="Women Marathon" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["marathon"].women} />
        </Card>
        <Card title="Men Marathon">
          <RecordsTable tableData={recordsData["marathon"].men} />
        </Card>
      </TabPane>
      <TabPane tab="Half Marathon" key="halfmarathon">
        <Card title="Women Half Marathon" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["half"].women} />
        </Card>
        <Card title="Men Half Marathon" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["half"].men} />
        </Card>
      </TabPane>
      <TabPane tab="20k" key="20k">
        <Card title="Women 20k" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["20k"].women} />
        </Card>
        <Card title="Men 20k" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["20k"].men} />
        </Card>
      </TabPane>
      <TabPane tab="10 Mile" key="10mile">
        <Card title="Women 10 Mile" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["10mile"].women} />
        </Card>
        <Card title="Men 10 Mile" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["10mile"].men} />
        </Card>
      </TabPane>
      <TabPane tab="7 Mile" key="7mile">
        <Card title="Women 7 Mile" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["7mile"].women} />
        </Card>
        <Card title="Men 7 Mile" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["7mile"].men} />
        </Card>
      </TabPane>
      <TabPane tab="10k" key="10k">
        <Card title="Women 10k" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["10k"].women} />
        </Card>
        <Card title="Men 10k" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["10k"].men} />
        </Card>
      </TabPane>
      <TabPane tab="8k" key="8k">
        <Card title="Women 8k" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["8k"].women} />
        </Card>
        <Card title="Men 8k" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["8k"].men} />
        </Card>
      </TabPane>
      <TabPane tab="5k" key="5k">
        <Card title="Women 5k" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["5k"].women} />
        </Card>
        <Card title="Men 5k" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["5k"].men} />
        </Card>
      </TabPane>
      <TabPane tab="1 Mile" key="1mile">
        <Card title="Women 1 Mile" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["mile"].women} />
        </Card>
        <Card title="Men 1 Mile" style={{ marginBottom: 10 }}>
          <RecordsTable tableData={recordsData["mile"].men} />
        </Card>
      </TabPane>
    </Tabs>
  );
}
