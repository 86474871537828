import React, { useEffect, useState, useReducer } from "react";
import "./App.scss";
import SidebarComponent from "./Components/Sidebar/Sidebar";
import HeaderComponent from "./Components/Header/Header";
import { Route, Switch, Redirect } from "react-router-dom";
import Athletes from "./Components/Athletes/Athletes";
// import News from "./Components/News/News";
import Supporters from "./Components/Supporters/Supporters";
import Records from "./Components/Records/Records";
import Home from "./Components/Home/Home";
import History from "./Components/History/History";
import useWindowDimensions from "./Helpers/useWindowDimensions";
import { Layout, Row } from "antd";

export default function App() {
  const [selectedItem, updateSelectedSidebarItem] = useState("Home");
  const { width } = useWindowDimensions();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const isMobile = width <= 768;

  // TODO
  // eslint-disable-next-line
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
    return () => window.removeEventListener("resize", forceUpdate);
  }, []);

  return (
    <div style={{ backgroundColor: "#e1e1e6", minHeight: "100vh" }}>
      <SidebarComponent
        selectedItem={selectedItem}
        onChange={updateSelectedSidebarItem}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
      />
      {sidebarExpanded && isMobile ? undefined : (
        <Row style={{ paddingLeft: isMobile ? 10 : 285, paddingTop: 30 }}>
          <HeaderComponent
            title={selectedItem}
            currentPage={selectedItem}
            onSelection={updateSelectedSidebarItem}
            isMobile={isMobile}
            windowWidth={width}
          />
        </Row>
      )}
      {sidebarExpanded && isMobile ? undefined : (
        <Layout.Content
          style={{
            padding: isMobile ? 10 : "24px 10px 10px 285px",
            overflowX: "hidden",
          }}
        >
          <Switch>
            <Route exact path="/">
              <Home updateSidebar={updateSelectedSidebarItem} isMobile />
            </Route>
            <Route path="/home">
              <Home updateSidebar={updateSelectedSidebarItem} isMobile />
            </Route>
            <Route path="/athletes">
              <Athletes updateSidebar={updateSelectedSidebarItem} />
            </Route>
            {/* <Route path="/news">
              <News updateSidebar={updateSelectedSidebarItem} />
            </Route> */}
            <Route path="/supporters">
              <Supporters
                updateSidebar={updateSelectedSidebarItem}
                isMobile={isMobile}
              />
            </Route>
            <Route path="/records">
              <Records updateSidebar={updateSelectedSidebarItem} />
            </Route>
            <Route path="/history">
              <History updateSidebar={updateSelectedSidebarItem} />
            </Route>
            <Route path="*">
              <Redirect to="/" />
              {/** TODO: Add a 404 route that updates the sidebar too */}
            </Route>
          </Switch>
        </Layout.Content>
      )}
    </div>
  );
}
