import React from "react";
import AlexWilson from "../../../Assets/images/athletes/women/AlexWilson.jpg";
import AmandaEdwards from "../../../Assets/images/athletes/women/AmandaEdwards.jpg";
import AngelaChaney from "../../../Assets/images/athletes/women/AngelaChaney.jpg";
import FlanneryCerbinBohach from "../../../Assets/images/athletes/women/FlanneryCerbinBohach.jpg";
import JenVanOtterloo from "../../../Assets/images/athletes/women/JenVanOtterloo.jpg";
import JessicaHruska from "../../../Assets/images/athletes/women/JessicaHruska.jpg";
import JordanWinke from "../../../Assets/images/athletes/women/JordanWinke.jpg";
import PascaMyers from "../../../Assets/images/athletes/women/PascaMyers.jpg";
import SamanthaWingert from "../../../Assets/images/athletes/women/SamanthaWingert.jpg";
import SarahBakula from "../../../Assets/images/athletes/women/SarahBakula.jpg";
import SusieDuke from "../../../Assets/images/athletes/women/SusieDuke.jpg";
import AdamBohach from "../../../Assets/images/athletes/men/AdamBohach.jpg";
import AustinOBrien from "../../../Assets/images/athletes/men/AustinOBrien.jpg";
import BenJaskowiak from "../../../Assets/images/athletes/men/BenJaskowiak.jpg";
import BlakeWhalen from "../../../Assets/images/athletes/men/BlakeWhalen.jpg";
import CalebAppleton from "../../../Assets/images/athletes/men/CalebAppleton.jpg";
import JasonThomas from "../../../Assets/images/athletes/men/JasonThomas.jpg";
import KyleWagner from "../../../Assets/images/athletes/men/KyleWagner.jpg";
import MacCoyBenzen from "../../../Assets/images/athletes/men/MacCoyBenzen.jpg";
import MichaelJohnson from "../../../Assets/images/athletes/men/MichaelJohnson.jpg";
import PhilYoung from "../../../Assets/images/athletes/men/PhilYoung.jpg";
import TerryNielsen from "../../../Assets/images/athletes/men/TerryNielsen.jpg";
import TysonWieland from "../../../Assets/images/athletes/men/TysonWieland.jpg";
import KallinKhan from "../../../Assets/images/athletes/men/KallinKhan.jpg";
import CodyBaele from "../../../Assets/images/athletes/men/CodyBaele.jpg";
import DannaHerrick from "../../../Assets/images/athletes/women/DannaHerrick.jpg";
import EmilyWebb from "../../../Assets/images/athletes/women/EmilyWebb.jpg";
import TylerLance from "../../../Assets/images/athletes/men/TylerLance.jpg";
import TriciaSerres from "../../../Assets/images/athletes/women/TriciaSerres.jpg";
import JoshEvans from "../../../Assets/images/athletes/men/JoshEvans.jpg";
import KaylaDeighan from "../../../Assets/images/athletes/women/KaylaDeighan.jpg";
import AlecBaldwin from "../../../Assets/images/athletes/men/AlecBaldwin.jpg";
import JoeySchultz from "../../../Assets/images/athletes/men/JoeySchultz.jpg";
import EthanHammerand from "../../../Assets/images/athletes/men/EthanHammerand.jpg";
import TaraAndersen from "../../../Assets/images/athletes/women/TaraAndersen.jpg";
import CallieOBrien from "../../../Assets/images/athletes/women/CallieOBrien.jpg";
import SamPinkowski from "../../../Assets/images/athletes/men/SamPinkowski.jpg";
import AnthonyKinley from "../../../Assets/images/athletes/men/AnthonyKinley.jpg";
import ZacharyKatzman from "../../../Assets/images/athletes/men/ZacharyKatzman.jpg";
import ElainaBiechler from "../../../Assets/images/athletes/women/ElainaBiechler.jpg";
import EliseEmmick from "../../../Assets/images/athletes/women/EliseEmmick.jpg";
import ConnorRoche from "../../../Assets/images/athletes/men/ConnorRoche.jpg";

const athleteImages = [
  {
    key: "AlexWilson",
    image: AlexWilson,
  },
  {
    key: "AmandaEdwards",
    image: AmandaEdwards,
  },
  {
    key: "AngelaChaney",
    image: AngelaChaney,
  },
  {
    key: "FlanneryCerbinBohach",
    image: FlanneryCerbinBohach,
  },
  {
    key: "JenVanOtterloo",
    image: JenVanOtterloo,
  },
  {
    key: "JessicaHruska",
    image: JessicaHruska,
  },
  {
    key: "JordanWinke",
    image: JordanWinke,
  },
  {
    key: "PascaMyers",
    image: PascaMyers,
  },
  {
    key: "SamanthaWingert",
    image: SamanthaWingert,
  },
  {
    key: "SarahBakula",
    image: SarahBakula,
  },
  {
    key: "SusieDuke",
    image: SusieDuke,
  },
  {
    key: "AdamBohach",
    image: AdamBohach,
  },
  {
    key: "AustinOBrien",
    image: AustinOBrien,
  },
  {
    key: "BenJaskowiak",
    image: BenJaskowiak,
  },
  {
    key: "BlakeWhalen",
    image: BlakeWhalen,
  },
  {
    key: "CalebAppleton",
    image: CalebAppleton,
  },
  {
    key: "JasonThomas",
    image: JasonThomas,
  },
  {
    key: "KyleWagner",
    image: KyleWagner,
  },
  {
    key: "MacCoyBenzen",
    image: MacCoyBenzen,
  },
  {
    key: "MichaelJohnson",
    image: MichaelJohnson,
  },
  {
    key: "PhilYoung",
    image: PhilYoung,
  },
  {
    key: "TerryNielsen",
    image: TerryNielsen,
  },
  {
    key: "TysonWieland",
    image: TysonWieland,
  },
  {
    key: "KallinKhan",
    image: KallinKhan,
  },
  {
    key: "CodyBaele",
    image: CodyBaele,
  },
  {
    key: "DannaHerrick",
    image: DannaHerrick,
  },
  {
    key: "EmilyWebb",
    image: EmilyWebb,
  },
  {
    key: "TylerLance",
    image: TylerLance,
  },
  {
    key: "TriciaSerres",
    image: TriciaSerres,
  },
  {
    key: "JoshEvans",
    image: JoshEvans,
  },
  {
    key: "KaylaDeighan",
    image: KaylaDeighan,
  },
  {
    key: "AlecBaldwin",
    image: AlecBaldwin,
  },
  {
    key: "JoeySchultz",
    image: JoeySchultz,
  },
  {
    key: "EthanHammerand",
    image: EthanHammerand,
  },
  {
    key: "TaraAndersen",
    image: TaraAndersen,
  },
  {
    key: "CallieOBrien",
    image: CallieOBrien,
  },
  {
    key: "SamPinkowski",
    image: SamPinkowski,
  },
  {
    key: "AnthonyKinley",
    image: AnthonyKinley,
  },
  {
    key: "ZacharyKatzman",
    image: ZacharyKatzman,
  },
  {
    key: "ElainaBiechler",
    image: ElainaBiechler,
  },
  {
    key: "EliseEmmick",
    image: EliseEmmick,
  },
  {
    key: "ConnorRoche",
    image: ConnorRoche,
  },
];

type AthleteImageProps = {
  imageKey: string;
};

export default function AthleteImage(props: AthleteImageProps) {
  const { imageKey } = props;
  const image = athleteImages.find((image) => image.key === imageKey);

  if (image) {
    return (
      <img
        style={{ maxWidth: "250px", maxHeight: "450px", borderRadius: 5 }}
        src={image?.image}
        alt="athlete"
      />
    );
  }

  return <p>No Athlete Image</p>;
}
