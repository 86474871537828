import React from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import "./header.scss";

const styles = StyleSheet.create({
  avatar: {
    height: 35,
    width: 35,
    borderRadius: 50,
    marginLeft: 14,
    border: "1px solid #DFE0EB",
  },
  container: {
    height: 40,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  name: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    textAlign: "right",
    letterSpacing: 0.2,
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  separator: {
    borderLeft: "1px solid #DFE0EB",
    marginLeft: 32,
    marginRight: 32,
    height: 32,
    width: 2,
    "@media (max-width: 768px)": {
      marginLeft: 12,
      marginRight: 12,
    },
  },
  title: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: "30px",
    letterSpacing: 0.3,
    "@media (max-width: 768px)": {
      marginLeft: 60,
    },
    "@media (max-width: 468px)": {
      fontSize: 20,
    },
  },
  iconStyles: {
    cursor: "pointer",
    marginLeft: 25,
    "@media (max-width: 768px)": {
      marginLeft: 12,
    },
  },
});

function Header(props: any) {
  const {
    icon,
    title,
    onSelection,
    isMobile,
    currentPage,
    onSelectChange,
    windowWidth,
    ...otherProps
  } = props;

  const mobileStyle = isMobile ? { height: 100, horizontal: "center" } : {};

  return (
    <Row
      className={css(styles.container)}
      vertical="center"
      {...mobileStyle}
      {...otherProps}
    >
      <span className={css(styles.title)}>
        {title === "Home" ? "Iowa's Elite Distance Running Club" : title}
      </span>
      <Row vertical="center">{undefined}</Row>
    </Row>
  );
}

export default Header;
