import React, { useEffect } from "react";
import { Row, Col, Card } from "antd";
import athletesData from "../../Data/athletes.json";
import "antd/dist/antd.css";
import "./athletes.scss";
import AthleteInfo from "./AthleteInfo/AthleteInfo";
import Standards from "../../Data/team-standards.pdf";

type AthletesProps = {
  updateSidebar: (e: string) => void;
};

export default function Athletes(props: AthletesProps) {
  const { updateSidebar } = props;

  useEffect(() => {
    updateSidebar("Athletes");
  }, [updateSidebar]);

  return (
    <div>
      <Col span={24} style={{ padding: 5 }}>
        <Card>
          <Row>
            <Col span={24} md={12} style={{ padding: 5 }}>
              <h1>Women</h1>
              <AthleteInfo athletes={athletesData.women} />
            </Col>
            <Col span={24} md={12} style={{ padding: 5 }}>
              <h1>Men</h1>
              <AthleteInfo athletes={athletesData.men} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24} style={{ padding: 5 }}>
        <Card style={{ padding: 5 }}>
          <h4>Interested in joining Runablaze Iowa?</h4>
          Please contact Tyson Wieland at tysonw123@live.com
          <br />
          <a href={Standards} rel="noopener noreferrer" target="_blank">
            Click to view team standards
          </a>
        </Card>
      </Col>
    </div>
  );
}
