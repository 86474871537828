import React from "react";
import { Card } from "antd";
import RecordsTable from "../RecordsTable/RecordsTable";
import recordsData from "../../../Data/records.json";
import "./mobile-records.scss";

export default function MobileRecords() {
  return (
    <div>
      <Card title="Women Marathon" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["marathon"].women} />
      </Card>
      <Card title="Men Marathon" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["marathon"].men} />
      </Card>
      <Card title="Women Half Marathon" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["half"].women} />
      </Card>
      <Card title="Men Half Marathon" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["half"].men} />
      </Card>
      <Card title="Women 20k" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["20k"].women} />
      </Card>
      <Card title="Men 20k" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["20k"].men} />
      </Card>
      <Card title="Women 10 Mile" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["10mile"].women} />
      </Card>
      <Card title="Men 10 Mile" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["10mile"].men} />
      </Card>
      <Card title="Women 7 Mile" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["7mile"].women} />
      </Card>
      <Card title="Men 7 Mile" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["7mile"].men} />
      </Card>
      <Card title="Women 10k" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["10k"].women} />
      </Card>
      <Card title="Men 10k" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["10k"].men} />
      </Card>
      <Card title="Women 8k" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["8k"].women} />
      </Card>
      <Card title="Men 8k" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["8k"].men} />
      </Card>
      <Card title="Women 5k" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["5k"].women} />
      </Card>
      <Card title="Men 5k" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["5k"].men} />
      </Card>
      <Card title="Women 1 Mile" style={{ marginBottom: 10 }}>
        <RecordsTable tableData={recordsData["mile"].women} />
      </Card>
      <Card title="Men 1 Mile">
        <RecordsTable tableData={recordsData["mile"].men} />
      </Card>
    </div>
  );
}
