import React, { useEffect } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import Logo from "./Logo/Logo";
import MenuItem from "./MenuItem/MenuItem";
import IconBurger from "../../Assets/icons/icon-burger";
import {
  faHandHoldingUsd,
  faHome,
  // faNewspaper,
  faTrophy,
  faUserFriends,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import "./Sidebar.scss";
import useWindowDimensions from "../../Helpers/useWindowDimensions";
import { Button } from "antd";

const styles = (width: number) =>
  StyleSheet.create({
    burgerIcon: {
      cursor: "pointer",
      position: "absolute",
      left: 24,
      top: 34,
    },
    container: {
      backgroundColor: "#000000",
      width: 255,
      paddingTop: 32,
      height: "100%",
    },
    containerMobile: {
      transition: "left 0.5s, right 0.5s",
      position: "absolute",
      width: width,
      height: "100%",
      zIndex: 901,
      overflow: "hidden",
    },
    mainContainer: {
      height: "100%",
      minHeight: "100vh",
      position: "fixed",
    },
    mainContainerMobile: {
      position: "absolute",
      width: "100vw",
      minWidth: "100%",
      top: 0,
      left: 0,
    },
    mainContainerExpanded: {
      width: "100%",
      minWidth: "100vh",
      position: "fixed",
    },
    menuItemList: {
      marginTop: 52,
      height: "100vh",
    },
    outsideLayer: {
      position: "absolute",
      width: "100vw",
      minWidth: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,.50)",
      zIndex: 900,
    },
    separator: {
      borderTop: "1px solid #DFE0EB",
      marginTop: 16,
      marginBottom: 16,
      opacity: 0.06,
    },
    hide: {
      left: -width,
    },
    show: {
      left: 0,
    },
  });

type sideBarProps = {
  selectedItem: string;
  onChange: (e: string) => void;
  sidebarExpanded: boolean;
  setSidebarExpanded: any;
};

export default function Sidebar(props: sideBarProps) {
  const { sidebarExpanded, setSidebarExpanded } = props;
  const width = useWindowDimensions().width;
  const isMobile = width <= 768;

  useEffect(() => {
    if (sidebarExpanded && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [sidebarExpanded, isMobile]);

  const onItemClicked = (item: string) => {
    setSidebarExpanded(false);
    return props.onChange(item);
  };

  const toggleMenu = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  const renderBurger = () => {
    return (
      <div onClick={toggleMenu} className={css(styles(width).burgerIcon)}>
        <IconBurger />
      </div>
    );
  };

  const renderCloseButton = () => {
    return (
      <div
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
        }}
        onClick={toggleMenu}
      >
        <Button
          type="primary"
          style={{
            backgroundColor: "#f15d22",
            borderColor: "black",
            color: "black",
          }}
        >
          <p>Close</p>
        </Button>
      </div>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <Row
        className={css(styles(width).mainContainer)}
        breakpoints={{
          768: css(
            styles(width).mainContainerMobile,
            sidebarExpanded && styles(width).mainContainerExpanded
          ),
        }}
      >
        {isMobile && !sidebarExpanded && renderBurger()}
        <Column
          className={css(styles(width).container)}
          style={{ width: width }}
          breakpoints={{
            768: css(
              styles(width).containerMobile,
              sidebarExpanded ? styles(width).show : styles(width).hide
            ),
          }}
        >
          <NavLink to="/">
            <div onClick={() => onItemClicked("Home")}>
              <Logo />
            </div>
          </NavLink>
          <Column className={css(styles(width).menuItemList)}>
            <NavLink to="/" className="no-link-style">
              <MenuItem
                title="Home"
                onMenuItemClick={() => onItemClicked("Home")}
                active={props.selectedItem === "Home"}
                faIcon={faHome}
              />
            </NavLink>
            {/* <NavLink to="/news" className="no-link-style">
              <MenuItem
                title="Team News"
                onMenuItemClick={() => onItemClicked("News")}
                active={props.selectedItem === "News"}
                faIcon={faNewspaper}
              />
            </NavLink> */}
            <NavLink to="/athletes" className="no-link-style">
              <MenuItem
                title="Team Athletes"
                onMenuItemClick={() => onItemClicked("Athletes")}
                active={props.selectedItem === "Athletes"}
                faIcon={faUserFriends}
              />
            </NavLink>
            <NavLink to="/supporters" className="no-link-style">
              <MenuItem
                title="Supporters"
                onMenuItemClick={() => onItemClicked("Supporters")}
                active={props.selectedItem === "Supporters"}
                faIcon={faHandHoldingUsd}
              />
            </NavLink>
            <NavLink to="/records" className="no-link-style">
              <MenuItem
                title="Club Records"
                onMenuItemClick={() => onItemClicked("Club Records")}
                active={props.selectedItem === "Club Records"}
                faIcon={faTrophy}
              />
            </NavLink>
            <NavLink to="/history" className="no-link-style">
              <MenuItem
                title="Iowa Running History"
                onMenuItemClick={() => onItemClicked("Iowa Running History")}
                active={props.selectedItem === "Iowa Running History"}
                faIcon={faBook}
              />
            </NavLink>
            {/* <div className={css(styles.separator)}></div>
            <NavLink to="/settings" className="no-link-style">
                <MenuItem
                    title="Settings"
                    onMenuItemClick={() => this.onItemClicked('Settings')}
                    active={this.props.selectedItem === 'Settings'}
                    faIcon={faCog} />
            </NavLink>
            <NavLink to="/subscription" className="no-link-style">
                <MenuItem
                    title="Subscription"
                    onMenuItemClick={() => this.onItemClicked('Subscription')}
                    active={this.props.selectedItem === 'Subscription'}
                    faIcon={faCreditCard} />
            </NavLink> */}
          </Column>
          {isMobile && sidebarExpanded && renderCloseButton()}
        </Column>
        {/* {isMobile && sidebarExpanded && (
          <div
            className={css(styles(width).outsideLayer)}
            onClick={toggleMenu}
          ></div>
        )} */}
      </Row>
    </div>
  );
}
