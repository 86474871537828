import React from "react";
import { Collapse } from "antd";
import "./athleteInfo.scss";
import "antd/dist/antd.css";
import AthleteImage from "./AthleteImage";

type AthleteInfoType = {
  name: string;
  imageKey: string;
  home: string;
  dob: string;
  prep: string;
  college: string;
  favoriteRace?: string;
  bests: {
    "5k": string;
    "10k": string;
    half: string;
    full: string;
  };
  other?: string[];
};

type AthleteInfoProps = {
  athletes: AthleteInfoType[];
};

export default function AthleteInfo(props: AthleteInfoProps) {
  const { Panel } = Collapse;
  const { athletes } = props;

  return (
    <Collapse accordion={true}>
      {athletes.map((athlete, i) => {
        const bests = athlete.bests;
        const anyBests =
          bests["5k"].length ||
          bests["10k"].length ||
          bests["half"].length ||
          bests["full"].length;

        return (
          <Panel
            header={athlete.name.concat("     |     ").concat(athlete.home)}
            key={athlete.imageKey}
          >
            <div className="info">
              <div className="column-left">
                <h4>About</h4>
                {athlete.dob.length ? (
                  <>
                    <h5>DOB:</h5>
                    <p>{athlete.dob}</p>
                  </>
                ) : (
                  <></>
                )}
                {athlete.prep.length ? (
                  <>
                    <h5>Prep:</h5>
                    <p>{athlete.prep}</p>
                  </>
                ) : (
                  <></>
                )}
                {athlete.college.length ? (
                  <>
                    <h5>College:</h5>
                    <p>{athlete.college}</p>
                  </>
                ) : (
                  <></>
                )}
                {athlete?.favoriteRace?.length ? (
                  <>
                    <h5>Favorite Iowa Road Race:</h5>
                    <p> {athlete.favoriteRace}</p>
                  </>
                ) : (
                  <></>
                )}
                {anyBests ? <h4>Personal Bests</h4> : <></>}
                {athlete?.bests["5k"].length ? (
                  <p>5k: {athlete.bests["5k"]}</p>
                ) : (
                  <></>
                )}
                {athlete?.bests["10k"].length ? (
                  <p>10k: {athlete.bests["10k"]}</p>
                ) : (
                  <></>
                )}
                {athlete?.bests["half"].length ? (
                  <p>Half Marathon: {athlete.bests["half"]}</p>
                ) : (
                  <></>
                )}
                {athlete?.bests["full"].length ? (
                  <p>Full Marathon: {athlete.bests["full"]}</p>
                ) : (
                  <></>
                )}
                {athlete.other?.map((other, i) => {
                  return <p key={i}>{other}</p>;
                })}
              </div>
              <div className="column-right">
                <AthleteImage imageKey={athlete.imageKey} />
              </div>
            </div>
          </Panel>
        );
      })}
    </Collapse>
  );
}
